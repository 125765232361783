import React from 'react';
import ReactDOM from 'react-dom/client';

import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Panel } from 'primereact/panel';
import { Tree } from 'primereact/tree';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { Menu } from 'primereact/menu';
import { ContextMenu } from 'primereact/contextmenu';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import { ScrollTop } from 'primereact/scrolltop';
import { Avatar } from 'primereact/avatar';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Paginator } from 'primereact/paginator';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toolbar } from 'primereact/toolbar';
import { TreeSelect } from 'primereact/treeselect';
import "primereact/resources/primereact.min.css"; 
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"; 
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./index.css";
// import reportWebVitals from './reportWebVitals';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-chrome";

import * as DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const AppContext = React.createContext();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loggedInUser: null, currentPage: "home"}
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }

  componentDidMount() {
    window.catalyst.auth.isUserAuthenticated().then(result => {
      this.setState({loggedInUser: result.content})
    }).catch(err => {
        window.location.href = "login.html";
    });
  }

  setCurrentPage(newPage) {
    this.setState({currentPage: newPage})
  }

  editEnabled() {
    return this.state.loggedInUser !== null &&
      (this.state.loggedInUser.role_details.role_id === "5781000004533168" || 
      this.state.loggedInUser.role_details.role_id === "5781000004543125")
  }

  render() {
    return (
      this.state.loggedInUser ?
      <AppContext.Provider value={{loggedInUser: this.state.loggedInUser, editEnabled: this.editEnabled()}}>
        <div className="grid">
          <div className="col-12"><Header setCurrentPage={this.setCurrentPage}/></div>
          <div className="col-12">{this.state.currentPage === "home" ? <MainContent/> : this.state.currentPage === "advStanceSearch" ? <AdvancedSearch/> : <Faq/>}</div>
          <ScrollTop  threshold={100}/>
        </div>
      </AppContext.Provider> :
      "Redirecting..."
    )
  }
}

class Header extends React.Component {
  static contextType = AppContext;
  logout() {
    window.catalyst.auth.signOut("https://" + document.domain + "/app/");
  }
  getGuestOverlayContent() {
    return (
        <div style={{textAlign: "center"}} >Welcome Guest.<br/><br/>
        <a href='login.html'><Button label="Login"/></a>
      </div>
    )
  }
  getUserOverlayPanel() {
    return (
      <div style={{textAlign: "center"}} >Welcome {this.context.loggedInUser.first_name}<br/><br/>
      {this.context.loggedInUser.email_id}<br/><br/>
      <Button label='Logout' onClick={this.logout}></Button>
      </div>
    )
  }
  render() {
    const items = [
      {
        label: 'Home',
        command: (event) => {
          this.props.setCurrentPage("home")
        }
      },
      {
        label: 'Search Stances',
        command: (event) => {
          this.props.setCurrentPage("advStanceSearch")
        }
      },
      {
        label: 'FAQs',
        command: (event) => {
          this.props.setCurrentPage("faqs")
        }
      }
    ];

    const start = <img alt="logo" src="pali_logo.png" height="40" className="mr-2"></img>;
    const end = <>
        <Avatar label={this.context.loggedInUser.first_name.charAt(0)} className="mr-2" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} onClick={(e) => this.op.toggle(e)} />
        <OverlayPanel ref={(el) => this.op = el} id="overlay_panel">
          {this.context.loggedInUser ? this.getUserOverlayPanel() : this.getGuestOverlayContent()}
        </OverlayPanel>
      </>;

    return (
      <div className="card">
          <Menubar model={items} start={start} end={end}/>
      </div>
    )
  }
}

class ActiveDiscussions extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {activeDiscussions: [], loading: false, isUnauthorised: false,
                  dialogOpen: false, dialogHeader: null, dialogAction: null,
                  delOpen: false, submitting: false,
                  managedDiscID: null, title: "", desc: "", initiator: ""}
    this.openDialog = this.openDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
  }
  openDialog(action, disc) {
    if (action === "add") {
      this.setState({dialogOpen: true, dialogHeader: "Add", dialogAction: action, managedDiscID: "", title: "", desc: "", initiator: ""})
    } else if (action === "edit") {
      this.setState({dialogOpen: true, dialogHeader: "Edit", dialogAction: action, managedDiscID: disc.id, title: disc.title, desc: disc.desc, initiator: disc.initiator})
    }
  }
  openDeleteDialog(disc) {
    this.setState({delOpen: true, managedDiscID: disc.id})
  }
  componentDidMount() {
    this.getDiscussion("getAll");
  }
  getDiscussion(action) {
    this.setState({loading: true})
    const xhr = new XMLHttpRequest();
    var url = action;
    if (action === "getIndividual") {
      url += "?discussionID="+this.state.managedDiscID
    }
    xhr.open("GET", "/server/manageActiveDiscussions/"+url, true);
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        var res = JSON.parse(xhr.responseText);
        if (action === "getIndividual") {
          this.setState({activeDiscussions: this.state.activeDiscussions.map(disc => {
            return (disc.id === this.state.managedDiscID) ? res : disc
          }), loading: false})
        } else {
          this.setState({activeDiscussions: res, loading: false})
        }
      } else if (xhr.status === 403) {
        this.setState({isUnauthorised: true, loading: false})
      }
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
      this.setState({loading: false})
    };
    xhr.send(null);
  }
  submit(action) {
    this.setState({submitting: true})
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/server/manageActiveDiscussions/"+action, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = (e) => {
      var severity = "error";
      var summary = "Failure"
      var detail = "Error performing the operation."
      if (xhr.status === 200) {
        severity = "success"
        summary = "Success"
        detail = (action === "add") ? "Added." : (action === "edit") ? "Edited." : "Deleted."
        if (action !== "delete") {
          this.setState({dialogOpen: false})
        }
        (action === "edit") ? this.getDiscussion("getIndividual") : this.getDiscussion("getAll")
      }
      this.toast.show({severity: severity, summary: summary, detail: detail});
      this.setState({submitting: false})
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
      this.setState({submitting: false})
    };
    var params = ""
    if (action === "add" || action === "edit") {
      params += "&title="+this.state.title+"&desc="+this.state.desc+"&initiator="+this.state.initiator
    }
    if (action === "edit" || action === "delete") {
      if(action === "edit") {
        params += "&"
      }
      params += "discussionID="+this.state.managedDiscID
    }
    xhr.send(params);
  }
  render() {
    return (<div>
      {this.state.isUnauthorised ? "Unauthorised Action." :
        this.state.loading ? <div style={{textAlign: "center"}}><ProgressSpinner/> <br/> Loading ...</div> :
          <>
            {this.context.editEnabled && <div style={{textAlign: "center"}}><br/>
              <Button className="p-button-sm p-button-outlined p-button-secondary" icon="pi pi-plus" tooltipOptions={{position: "bottom"}} tooltip='Add' onClick={() => this.openDialog("add")}/></div>}
            {(this.state.activeDiscussions.length === 0) && <p>There are no active discussions happening now.</p>}
            {this.state.activeDiscussions.map((discussion, index) => {
              return(<div key={index}> {index > 0 && <><br/><hr/><br/></>}
                <h4>{index+1}. {discussion.title}</h4>
                {parse(DOMPurify.sanitize(discussion.desc, { ADD_ATTR: ['target'] }))}
                Initiated by: <b>{discussion.initiator}</b>
                {this.context.editEnabled ? <div style={{textAlign: "center"}}><br/> <span className="p-buttonset">
                <Button className="p-button-sm p-button-outlined p-button-secondary" icon="pi pi-pencil" tooltipOptions={{position: "bottom"}} tooltip='Edit' onClick={() => this.openDialog("edit", discussion)}/>
                <Button className="p-button-sm p-button-outlined p-button-danger" icon="pi pi-trash" tooltipOptions={{position: "bottom"}} tooltip='Delete' onClick={() => this.openDeleteDialog(discussion)}/></span></div> : <></>}
                </div>)
              })
            }
            <Toast ref={(el) => this.toast = el} />
            <Dialog header={this.state.dialogHeader} visible={this.state.dialogOpen} onHide={() => this.setState({dialogOpen: false})} style={{ width: '100pc' }}>
              <Divider type="dashed" align="left"><span className="p-tag">Title</span></Divider>
              <InputText value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} />
              <Divider type="dashed" align="left"><span className="p-tag">Description</span></Divider>
              <Editor style={{ height: '175px' }} value={this.state.desc} onTextChange={(e) => this.setState({desc: e.htmlValue})} />
              <Divider type="dashed" align="left"><span className="p-tag">Initator</span></Divider>
              <InputText value={this.state.initiator} onChange={(e) => this.setState({initiator: e.target.value})} />
              <Divider type="dashed"/>
              <Button label="Submit" className="p-button-raised p-button-text" onClick={() => this.submit(this.state.dialogAction)} loading={this.state.submitting} disabled={this.state.submitting}/>
            </Dialog>
            <ConfirmDialog visible={this.state.delOpen} onHide={() => this.setState({delOpen: false})} message="Are you sure you want to proceed?"
              header="Delete" icon="pi pi-exclamation-triangle" accept={() => this.submit("delete")} acceptClassName="p-button-danger"/>
          </>
      }
    </div>)
  }
}

class MainContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {topics: [], treeNodes: [], currentTopic: null, isUnauthorised: false}
    this.setCurrentTopic = this.setCurrentTopic.bind(this);
    this.transformTopicsJson = this.transformTopicsJson.bind(this);
    this.populateTopics = this.populateTopics.bind(this);
    this.getTopic = this.getTopic.bind(this);
  }
  getInProgressRenderContent() {
    return(<div style={{textAlign: "center"}}><ProgressSpinner/> <br/> Loading ...</div>)
  }
  setCurrentTopic(curTopic) {
    this.setState({currentTopic: curTopic})
  }
  getTopic(id) {
    return this.recursiveTopicFinder(this.state.topics, id)
  }
  recursiveTopicFinder(topics, id) {
    for (let index = 0; index < topics.length; index++) {
      const topic = topics[index];
      if (topic.id === id) {
        return topic
      } else if (topic.sub_topics.length > 0) {
        var t = this.recursiveTopicFinder(topic.sub_topics, id)
        if (t != null) {
          return t
        }
      }
    }
    return null
  }
  transformTopicsJson(topics) {
    return topics.map(topic => {
      return {
        orig: topic,
        key: topic.id,
        label: topic.name,
        children: this.transformTopicsJson(topic.sub_topics),
        draggable: false,
        droppable: false,
        selectable: true
      }
    })
  }
  populateTopics() {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", "/server/getAllTopics/", true);
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        var ts = JSON.parse(xhr.responseText)
        var tns = this.transformTopicsJson(ts);
        this.setState({topics: ts, treeNodes: tns, currentTopic: ts[0]})
      } else if (xhr.status === 403) {
        this.setState({isUnauthorised: true})
      }
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
    };
    xhr.send(null);
  }
  componentDidMount() {
    this.populateTopics();
  }

  render() {
    return (
      <Splitter>
        <SplitterPanel size={20} minSize={20}>
        <Panel header="Topics">
          {
            this.state.isUnauthorised ? "Unauthorised Action." :
              this.state.topics.length > 0 ? <ScrollPanel style={{height: "calc(100vh - 200px)"}}>
              <TopicSelectorSidebar data={this.state.treeNodes} currentTopic={this.state.currentTopic} onChange={this.setCurrentTopic} updateTopics={this.populateTopics} getTopic={this.getTopic}/></ScrollPanel> :
              this.getInProgressRenderContent()
          }
        </Panel>
        </SplitterPanel>
        <SplitterPanel size={60} minSize={50}>
          <Panel header="Info">
            {
              this.state.isUnauthorised ? "Unauthorised Action." :
                this.state.currentTopic ? <ScrollPanel style={{height: "calc(100vh - 200px)"}}>
                <StanceList topic={this.state.currentTopic} getTopic={this.getTopic} topicNodeTree={this.state.treeNodes}/></ScrollPanel> :
                this.getInProgressRenderContent()
            }
            </Panel>
        </SplitterPanel>
        <SplitterPanel size={20} minSize={20}>
          <Panel header="Active Discussions">
            <ScrollPanel style={{height: "calc(100vh - 200px)"}}>
              <ActiveDiscussions/> 
            </ScrollPanel>
          </Panel>
        </SplitterPanel>
      </Splitter>
    )
  }
}

class TopicSelectorSidebar extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props)
    this.state = {selectedTopic: null, editorDialogOpen: false,
      editorDialogHeader: null, editorDialogSubmit: null,
      editorTextField: "", delDialogOpen: false, submitting: false
    }
    this.submit = this.submit.bind(this);
    this.toggleDelDialog = this.toggleDelDialog.bind(this);
  }
  toggleDelDialog(msg) {
    this.setState({delDialogOpen: msg})
  }
  submit(url, params) {
    this.setState({submitting: true})
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = (e) => {
      var severity = "error";
      var summary = "Failure"
      var detail = "Error performing the operation."
      if (xhr.status === 200) {
        severity = "success"
        summary = "Success"
        detail = "Action completed."
        this.setState({selectedTopic: null, editorDialogOpen: false,
          editorDialogHeader: null, editorDialogSubmit: null,
          editorTextField: "", submitting: false
        })
        this.props.updateTopics();
      }
      this.toast.show({severity: severity, summary: summary, detail: detail});
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
      this.setState({submitting: false})
    };
    xhr.send(params);
  }
  getMenuItems() {
    return [      
      {
        label: 'Add Sub-topic',
        icon: 'pi pi-plus',
        command: () => {
          var x = this
          var selectedTopic = this.state.selectedTopic
          this.setState({
            editorDialogOpen: true, editorDialogHeader: "Add Sub-topic",
            editorDialogSubmit: function (subtopic_name) {
              var params = "parent_id="+selectedTopic+"&subtopic_name="+x.state.editorTextField
              var url = "/server/manageTopic/"
              x.submit(url, params);
            }
          })
        }
      },
      {
        label: 'Add Stance',
        icon: 'pi pi-plus',
        command: () => { 
          var x = this
          var selectedTopic = this.state.selectedTopic
          this.setState({
            editorDialogOpen: true, editorDialogHeader: "Add Stance",
            editorDialogSubmit: function () {
              var params = "topic_id="+selectedTopic+"&stance_title="+x.state.editorTextField
              var url = "/server/manageStance/"
              x.submit(url, params);
            }
          })
        }
      },
      {
        label: 'Edit Topic',
        icon: 'pi pi-pencil',
        command: () => { 
          var x = this
          var selectedTopic = this.state.selectedTopic
          var topic = this.props.getTopic(selectedTopic)
          this.setState({
            editorDialogOpen: true, editorDialogHeader: "Edit Topic", editorTextField: topic.name,
            editorDialogSubmit: function (topic_name) {
              var params = "topic_id="+selectedTopic+"&topic_name="+x.state.editorTextField
              var url = "/server/manageTopic/"
              x.submit(url, params);
            }
          })
        }
      },
      {
        label: 'Delete Topic',
        icon: 'pi pi-trash',
        command: () => { 
          var x = this
          var selectedTopic = this.state.selectedTopic
          this.setState({delDialogOpen: true,
            editorDialogSubmit: function (stance_title) {
              var params = "del_topic_id="+selectedTopic
              var url = "/server/manageTopic/"
              x.submit(url, params);
            }
          })
        }
      }
    ]
  }
  render() {
    return(
      <><ContextMenu model={this.getMenuItems()} ref={el => this.cm = el} onHide={() => this.setState({ selectedTopic: null })} />
      <Tree value={this.props.data} filter selectionMode="single" selectionKeys={this.props.currentTopic} onSelect={e => {this.props.onChange(e.node.orig); window.scrollTo(0, 0)}} onContextMenu={e => {this.context.editEnabled && this.cm.show(e.originalEvent)}} contextMenuSelectionKey={this.state.selectedTopic} onContextMenuSelectionChange={e => this.setState({selectedTopic: e.value})}/>
      <Toast ref={(el) => this.toast = el} />
      <Dialog header={this.state.editorDialogHeader} visible={this.state.editorDialogOpen} onHide={() => this.setState({editorDialogOpen: false})}>
        <InputText value={this.state.editorTextField} onChange={(e) => this.setState({editorTextField: e.target.value})} /> <br/><br/>
        <Button label="Submit" className="p-button-raised p-button-text" onClick={this.state.editorDialogSubmit} loading={this.state.submitting} disabled={this.state.submitting}/>
      </Dialog>
      <ConfirmDialog visible={this.state.delDialogOpen} onHide={() => this.toggleDelDialog(false)} message="Are you sure you want to proceed?"
    header="Delete Topic" icon="pi pi-exclamation-triangle" accept={this.state.editorDialogSubmit} acceptClassName="p-button-danger"/>
      </>
    )
  }
}

// Weightage based tag--- courtesy Santhosh Balaji
class WeightedTag extends React.Component {
  render() {
    var weight = ((Number(this.props.weight) + 2) * 100).toString()
    var color = ["blue", "green", "yellow", "orange", "red"]
    return(
      <div className="weighted-tag-container" style={{borderLeftColor: color[this.props.weight-1], fontWeight: weight}}>
        <div className="wtc-tag-div">
          <span>{this.props.tag}</span>
        </div>
      </div>
    )
  }
}

class StanceList extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.getStances = this.getStances.bind(this);
    this.getAssociations = this.getAssociations.bind(this);
    this.getStanceListRenderContent = this.getStanceListRenderContent.bind(this);
    this.getSampleCodeDialogFooter = this.getSampleCodeDialogFooter.bind(this);
    this.getTopToolbarLeftContent = this.getTopToolbarLeftContent.bind(this);
    this.getTopToolbarRightContent = this.getTopToolbarRightContent.bind(this);
    this.expandAllStances = this.expandAllStances.bind(this);
    this.collapseAllStances = this.collapseAllStances.bind(this);
    this.getAssociatedNode = this.getAssociatedNode.bind(this);
    this.openAssociationEditorDialog = this.openAssociationEditorDialog.bind(this);
    this.updateEditedAssociations = this.updateEditedAssociations.bind(this);
    this.submitAssociations = this.submitAssociations.bind(this);
    this.toggleSampleCodeDialog = this.toggleSampleCodeDialog.bind(this);
    this.toggleAddOpen = this.toggleAddOpen.bind(this);
    this.addVersion = this.addVersion.bind(this);
    this.submit = this.submit.bind(this);
    this.submitSampleCode = this.submitSampleCode.bind(this);
    this.disableAllVersionRefresh = this.disableAllVersionRefresh.bind(this);
    this.state = {loading: false, assnLoading: false, stances: [], 
      associations: [], editedAssociations: [],
      mouseOverStance: null, requiresAllVersionRefresh: false, activeStanceListIndex: [], submitting: false,
      assnDialogOpen:false, editorDialogOpen: false, editorDialogHeader: null, editorDialogSubmit: null,
      editorTextField: "", delDialogOpen: false, sampleCode: null,
      sampleCodeDialogOpen: false, sampleCodeEditorReadOnly: true}
  }
  getAssociatedNode(nodes, key) {
    for (let i = 0; i < nodes.length; i++) {
      var node = nodes[i]
      if (node.key === key) {
        return node;
      } else {
        var n = this.getAssociatedNode(node.children, key)
        if (n) {
          return n
        }
      }
    }
  }
  updateEditedAssociations(action, index, field, value) {
    if(action === "add") {
      this.setState({editedAssociations: this.state.editedAssociations.concat({action: "add", id: null, associated: null, weight: null, to_be_deleted: false})})
    } else if (action === "edit") {
      this.setState({editedAssociations: this.state.editedAssociations.map((ea, i) => {
        if (i === index) {
          var ac = ea.action
          if (ac !== "add") {
            ac = action
          }
          if (field === "associated") {
            return {action: ac, id: ea.id, associated: value, weight: ea.weight, to_be_deleted: ea.to_be_deleted}
          } else if (field === "weight") {
            return {action: ac, id: ea.id, associated: ea.associated, weight: value, to_be_deleted: ea.to_be_deleted}
          } else if (field === "to_be_deleted") {
            return {action: ac, id: ea.id, associated: ea.associated, weight: ea.weight, to_be_deleted: value}
          }
        } else {
          return ea
        }
      })})
    } else if (action === "remove") {
      this.setState({editedAssociations: this.state.editedAssociations.filter((ea, i) => {
        if (i !== index) {
          return ea
        }
      })})
    }
  }
  submitAssociations() {
    this.setState({assnLoading: true, associations: []})
    let resp = fetch("/server/manageAssociations/update", {method: "POST", headers: {'Content-Type': 'application/json;charset=utf-8'}, body: JSON.stringify({topic: this.props.topic.id, changes: this.state.editedAssociations})})
    resp.then( res => {this.setState({assnLoading: false, assnDialogOpen: false}); this.getAssociations(this.props.topic)})
  }
  openAssociationEditorDialog() {
    this.setState({assnDialogOpen: true, editedAssociations: this.state.associations.map(assn => {
      return {action: "existing", id: assn.key, associated: this.getAssociatedNode(this.props.topicNodeTree, assn.topic.id).key, weight: assn.weight, to_be_deleted: false}
    })})
  }
  toggleSampleCodeDialog() {
    this.setState({sampleCodeDialogOpen: !this.state.sampleCodeDialogOpen})
  }
  toggleAddOpen() {
    this.setState({addOpen: !this.state.addOpen})
  }
  toggleEditOpen() {
    this.setState({editOpen: !this.state.editOpen})
  }
  toggleDelDialog(msg) {
    this.setState({delDialogOpen: msg})
  }
  getAssociations(topic) {
    this.setState({assnLoading: true, associations: []})
    const xhr = new XMLHttpRequest();
    xhr.open("GET", "/server/manageAssociations/get?topicId="+topic.id, true);
    xhr.onload = (e) => {
      var js = JSON.parse(xhr.responseText);
      this.setState({associations: js.map(assn => {
        return {key: assn.id, topic: this.props.getTopic(assn.associated), weight: assn.weight}
      }), assnLoading: false})
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
      this.setState({assnLoading: false})
    };
    xhr.send(null);
  }
  getStances(topic) {
    this.setState({loading: true, activeStanceListIndex: []})
    const xhr = new XMLHttpRequest();
    xhr.open("GET", "/server/getTopicStances/?topic_id="+topic.id, true);
    xhr.onload = (e) => {
      var js = JSON.parse(xhr.responseText);
      this.setState({stances: js, loading: false})
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
      this.setState({loading: false})
    };
    xhr.send(null);
  }
  submit(url, params) {
    this.setState({submitting: true})
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = (e) => {
      var severity = "error";
      var summary = "Failure"
      var detail = "Error performing the operation."
      if (xhr.status === 200) {
        severity = "success"
        summary = "Success"
        detail = "Action completed."
        this.setState({selectedTopic: null, editorDialogOpen: false,
          editorDialogHeader: null, editorDialogSubmit: null,
          editorTextField: "", submitting: false
        })
        this.getStances(this.props.topic)
      } else {
        this.setState({submitting: false})
      }
      this.toast.show({severity: severity, summary: summary, detail: detail});
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
      this.setState({submitting: false})
    };
    xhr.send(params);
  }
  submitSampleCode() {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/server/manageTopic/", true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    var params = "topic_id="+this.props.topic.id+"&sample_code="+this.state.sampleCode
    xhr.onload = (e) => {
      var severity = "error";
      var summary = "Failure"
      var detail = "Error performing the operation."
      if (xhr.status === 200) {
        severity = "success"
        summary = "Success"
        detail = "Action completed."
        this.setState({sampleCodeEditorReadOnly: true})
      }
      this.toast.show({severity: severity, summary: summary, detail: detail});
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
    };
    xhr.send(params);
  }
  addVersion(action, params) {
    this.setState({submitting: true})
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/server/manageStanceVersion/", true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = (e) => {
      var severity = "error";
      var summary = "Failure"
      var detail = "Error performing the operation."
      if (xhr.status === 200) {
        severity = "success"
        summary = "Success"
        detail = "New version added."
        this.setState({requiresAllVersionRefresh: true, submitting: false})
        this.toggleAddOpen()
      }
      this.toast.show({severity: severity, summary: summary, detail: detail});
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
      this.setState({submitting: false})
    };
    params += "&stanceId="+this.state.mouseOverStance.id
    xhr.send(params);
  }
  componentDidMount() {
    this.getStances(this.props.topic);
    this.getAssociations(this.props.topic);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.topic.id !== nextProps.topic.id) {
      this.getStances(nextProps.topic)
      this.getAssociations(nextProps.topic)
    }
    return true;
  }
  disableAllVersionRefresh() {
    this.setState({requiresAllVersionRefresh: false})
  }
  expandAllStances() {
    this.setState({activeStanceListIndex: this.state.stances.map((_, index) => {
        return index
      })
    })
  }
  collapseAllStances() {
    this.setState({activeStanceListIndex: []})
  }
  getMenuItems() {
    return [
        {
            label: 'Manage Stance',
            items: [
                {
                  label: 'Add Version',
                  icon: 'pi pi-plus',
                  command: () => { this.toggleAddOpen(); }
                },
                {
                  label: 'Edit',
                  icon: 'pi pi-pencil',
                  command: () => { 
                    var x = this
                    var selectedStance = this.state.mouseOverStance
                    this.setState({
                      editorDialogOpen: true, editorDialogHeader: "Edit Stance", editorTextField: selectedStance.title,
                      editorDialogSubmit: function (topic_name) {
                        var params = "stance_id="+selectedStance.id+"&stance_title="+x.state.editorTextField
                        var url = "/server/manageStance/"
                        x.submit(url, params);
                      }
                    })
                  }
              },
              {
                  label: 'Delete',
                  icon: 'pi pi-trash',
                  command: () => { 
                    var x = this
                    var selectedStance = this.state.mouseOverStance
                    this.setState({delDialogOpen: true,
                      editorDialogSubmit: function (stance_title) {
                        var params = "del_stance_id="+selectedStance.id
                        var url = "/server/manageStance/"
                        x.submit(url, params);
                      }
                    })
                  }
              }
            ]
        }
    ]
  }
  getStanceListRenderContent() {
    if(this.state.loading) {
      return(<div style={{textAlign: "center"}}><ProgressSpinner/> <br/> Loading ...</div>)
    } else if(this.state.stances.length > 0) {
      return(<Accordion multiple activeIndex={this.state.activeStanceListIndex} onTabChange={(e) => this.setState({ activeStanceListIndex: e.index })}>
        {this.state.stances.map(stance => {
          return(<AccordionTab key={stance.id} header={this.context.editEnabled ? <React.Fragment>&nbsp;<i className="pi pi-bars" onMouseOver={(event) => {this.menu.toggle(event); this.setState({mouseOverStance: stance})}} aria-controls="popup_menu" aria-haspopup /><span>&nbsp;&nbsp;{stance.title}</span></React.Fragment> : stance.title}><StanceVersion stance={stance} requiresAllVersionRefresh={this.state.requiresAllVersionRefresh} disableAllVersionRefresh={this.disableAllVersionRefresh}/></AccordionTab>)
        })}
      </Accordion>)
    } else {
      return(<><br/><p style={{textAlign: "center"}}>There are no stances yet for " {this.props.topic.name} " topic, try another.</p></>)
    }
  }
  getSampleCodeDialogFooter() {
    if(!this.context.editEnabled) {
      return null
    }
    if(this.state.sampleCodeEditorReadOnly) {
      return (<Button className="p-button-sm" label="Edit" onClick={() => this.setState({sampleCodeEditorReadOnly: false})}/>)
    } else {
      return(<><Button className="p-button-sm" label="Cancel" onClick={() => this.setState({sampleCodeEditorReadOnly: true, sampleCode: this.props.topic.sample_code})}/>
      &nbsp;<Button className="p-button-sm" label="Submit" onClick={this.submitSampleCode}/></>)
    }
  }
  getTopToolbarLeftContent() {
    var currentTopic = this.props.topic;
    var topic_hierarchy = [currentTopic.name];
    while (currentTopic.parent !== null) {
      currentTopic = this.props.getTopic(currentTopic.parent)
      topic_hierarchy.push(currentTopic.name)
    }
    return (<>
      <i className="pi pi-home"></i> &nbsp; <i className="pi pi-angle-right"></i>
      {topic_hierarchy.reverse().map((topic, index) => {
        if(index === 0) {
          return <span key={index}>&nbsp;{topic}</span>
        } else {
          return (<span key={index}>&nbsp;<i className="pi pi-angle-right"></i> {topic}</span>)
        }
      })}      
      </>)
  }
  getTopToolbarRightContent() {
    return (
      <span className="p-buttonset">
      {this.state.stances.length > 0 &&
      <><Button className='p-button-sm p-button-outlined' icon="pi pi-angle-double-down" tooltipOptions={{position: "bottom"}} tooltip='Expand All' onClick={this.expandAllStances}/>
      <Button className='p-button-sm p-button-outlined' icon="pi pi-angle-double-up" tooltipOptions={{position: "bottom"}} tooltip='Collapse All' onClick={this.collapseAllStances}/></>
      }
      <Button className='p-button-sm p-button-outlined' icon="pi pi-code" tooltipOptions={{position: "bottom"}} tooltip='Sample Code' onClick={this.toggleSampleCodeDialog}/>
      </span>)
  }
  render() {
    return(<>
      <Toolbar style={{background: "transparent"}} left={this.getTopToolbarLeftContent()} right={this.getTopToolbarRightContent()} />      
      <Dialog header="Latest Sample Code" footer={this.getSampleCodeDialogFooter()} visible={this.state.sampleCodeDialogOpen} onHide={this.toggleSampleCodeDialog} style={{ height: '100pc', width: '100pc' }}>
        <AceEditor
          mode="yaml"
          theme="chrome"
          name="sample_code_editor"
          defaultValue={this.props.topic.sample_code}
          fontSize={16}
          height="100%"
          width="100%"
          showPrintMargin={false}
          onChange={(newCode) => this.setState({sampleCode: newCode})}
          readOnly={this.state.sampleCodeEditorReadOnly}
        />
      </Dialog>
      <Menu model={this.getMenuItems()} popup ref={el => this.menu = el} id="popup_menu"/>
      <Toast ref={(el) => this.toast = el} />
      {this.getStanceListRenderContent()}
      {(!this.state.assnLoading && (this.state.associations.length > 0 || this.context.editEnabled)) &&
      <><br/><Panel header="Related Topics">
      {this.context.editEnabled && <div style={{textAlign: "center"}}>
      <Button className="p-button-sm p-button-outlined p-button-secondary" icon="pi pi-pencil" tooltipOptions={{position: "bottom"}} tooltip='Update Related Topics' onClick={this.openAssociationEditorDialog}/></div>}
        
        <div className='wtc-tags-div'>
        {this.state.associations.map(assn => {
          return (
            <WeightedTag key={assn.key} weight={assn.weight} tag={assn.topic.name}/>
          )
        })}</div>
      </Panel></>}
      <Dialog header="Edit Related Topics" footer={<Button label="Submit" onClick={this.submitAssociations} loading={this.state.assnLoading} disabled={this.state.assnLoading}/>} visible={this.state.assnDialogOpen} onHide={() => this.setState({assnDialogOpen: false})} style={{ width: '50pc' }}>
        <div><div className='grid'>
          <div className="col-4"><h3>Related Topic</h3></div>
          <div className="col-8"><h3>Weightage</h3></div>
        </div>
        {this.state.editedAssociations.map((ea, index) => {
          return (<div key={index} className="grid">
            <div className="col-4">            
            <TreeSelect value={ea.associated} options={this.props.topicNodeTree} onChange={(e) => this.updateEditedAssociations("edit", index, "associated", e.value)} placeholder="Select Topic" filter/>
            </div>
            <div className="col-8">
            <InputNumber value={ea.weight} onValueChange={(e) => this.updateEditedAssociations("edit", index, "weight", e.value)} mode="decimal" showButtons min={1} max={5} />
            &nbsp;&nbsp;
            {ea.action === "add" && <><i className="pi pi-times" onClick={(e) => {this.updateEditedAssociations("remove", index)}}/>&nbsp;&nbsp; Remove this new entry.</>}
            {(ea.action === "edit" || ea.action === "existing") && <><Checkbox checked={ea.to_be_deleted} onChange={e => this.updateEditedAssociations("edit", index, "to_be_deleted", e.checked)}/>&nbsp;&nbsp; Mark for deletion.</>}
            <br/><br/>
            </div>
          </div>)
        })}
        <Button className="p-button-sm p-button-outlined" label="Add another topic" onClick={() => this.updateEditedAssociations("add")}/>
        </div>
      </Dialog>
      <Dialog header="Add Version" visible={this.state.addOpen} onHide={this.toggleAddOpen} style={{ height: '100pc', width: '100pc' }}>
        <StanceVersionEditor action="add" onSubmit={this.addVersion} submitting={this.state.submitting}/>
      </Dialog><Toast ref={(el) => this.toast = el} />
      <Dialog header={this.state.editorDialogHeader} visible={this.state.editorDialogOpen} onHide={() => this.setState({editorDialogOpen: false})}>
        <InputText value={this.state.editorTextField} onChange={(e) => this.setState({editorTextField: e.target.value})} /> <br/><br/>
        <Button label="Submit" className="p-button-raised p-button-text" onClick={this.state.editorDialogSubmit} loading={this.state.submitting} disabled={this.state.submitting}/>
      </Dialog>
      <ConfirmDialog visible={this.state.delDialogOpen} onHide={() => this.toggleDelDialog(false)} message="Are you sure you want to proceed?"
    header="Delete Stance" icon="pi pi-exclamation-triangle" accept={this.state.editorDialogSubmit} acceptClassName="p-button-danger"/>
    </>)
  }
}

class StanceVersion extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {allVersions: [], currVerIndex: null, submitting: false,
      timelineVersions: [], allVersionsVisible: false, loading: true,
      addOpen: false, editOpen: false, delOpen: false,
    }
    this.getPaginatorLeftContent = this.getPaginatorLeftContent.bind(this);
    this.getPaginatorRightContent = this.getPaginatorRightContent.bind(this);
    this.getAllVersions = this.getAllVersions.bind(this);
    this.showPrevStance = this.showPrevStance.bind(this);
    this.showNextStance = this.showNextStance.bind(this);
    this.getSpecificVersion = this.getSpecificVersion.bind(this);
    this.stanceVersionAPICall = this.stanceVersionAPICall.bind(this);
    this.toggleAllVersionsVisibility = this.toggleAllVersionsVisibility.bind(this);
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this);
    this.toggleEditOpen = this.toggleEditOpen.bind(this);
    this.toggleDelDialog = this.toggleDelDialog.bind(this);
  }
  toggleAllVersionsVisibility() {
    this.setState({allVersionsVisible: !this.state.allVersionsVisible})
  }
  toggleEditOpen() {
    this.setState({editOpen: !this.state.editOpen})
  }
  toggleDelDialog(msg) {
    this.setState({delOpen: msg})
  }
  getAllVersions() {
    this.stanceVersionAPICall("/server/getStanceVersion/", "stance_id="+this.props.stance.id, true)
  }
  getSpecificVersion() {
    this.stanceVersionAPICall("/server/getStanceVersion/", "stance_version_id="+this.state.allVersions[this.state.currVerIndex].id, false)
  }
  showPrevStance() {
    this.setState({currVerIndex: this.state.currVerIndex + 1})
  }
  showNextStance() {
    this.setState({currVerIndex: this.state.currVerIndex - 1})
  }
  componentDidMount() {
    this.getAllVersions()
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.requiresAllVersionRefresh === false && nextProps.requiresAllVersionRefresh === true) {
      this.getAllVersions()
      this.props.disableAllVersionRefresh();
    }
    return true;
  }
  stanceVersionAPICall(url, params, isAll) {
    this.setState({loading: true})
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = (e) => {
      var js = JSON.parse(xhr.responseText);
      if (isAll) {
        this.setState({allVersions: js.reverse(), timelineVersions: JSON.parse(xhr.responseText), loading: false, currVerIndex: js.length > 0 ? js.length-1 : null})
      } else {
        this.setState({allVersions: this.state.allVersions.map((ver, index) => {
          return (index === this.state.currVerIndex) ? js : ver}),
          loading: false})
      }
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
    };
    xhr.send(params);
  }
  submit(action, params) {
    this.setState({submitting: true})
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/server/manageStanceVersion/", true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = (e) => {
      var severity = "error";
      var summary = "Failure"
      var detail = "Error performing the operation."
      if (xhr.status === 200) {
        severity = "success"
        summary = "Success"
        detail = (action === "edit") ? "Version edited." : "Version deleted."
        if (action !== "del") {
          this.toggleEditOpen()
        }
        (action === "edit") ? this.getSpecificVersion() : this.getAllVersions()
      }
      this.toast.show({severity: severity, summary: summary, detail: detail});
      this.setState({submitting: false})
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
      this.setState({submitting: false})
    };
    params += "&stanceId="+this.props.stance.id
    xhr.send(params);
  }
  delete() {
    this.submit("del", "action=del&stanceVersionId="+this.state.allVersions[this.state.currVerIndex].id);
  }

  getPaginatorLeftContent() {
    return (<Button className="p-button-sm p-button-outlined" icon="pi pi-history" tooltipOptions={{position: "bottom"}} tooltip='Versions Timeline' disabled={this.state.allVersions.length === 1} onClick={this.toggleAllVersionsVisibility} />)
  };

  getPaginatorRightContent() {
    return (this.context.editEnabled ? <span className="p-buttonset">
    <Button className="p-button-sm p-button-outlined p-button-secondary" icon="pi pi-pencil" tooltipOptions={{position: "bottom"}} tooltip='Edit Version' onClick={this.toggleEditOpen}/>
    <Button className="p-button-sm p-button-outlined p-button-danger" icon="pi pi-trash" tooltipOptions={{position: "bottom"}} tooltip='Delete Version' onClick={() => this.toggleDelDialog(true)}/></span> : <></>)
  };

  template = {
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink', // The above keys can be set in the desired order.
    'FirstPageLink': (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
            <span className="p-3">Initial</span>
        </button>
      )
    },
    'PrevPageLink': (options) => {
      return (
          <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
              <span className="p-3">Previous</span>
          </button>
      )
    },
    'NextPageLink': (options) => {
      return (
          <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
              <span className="p-3">Next</span>
          </button>
      )
    },
    'LastPageLink': (options) => {
      return (
          <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
              <span className="p-3">Latest</span>
          </button>
      )
    }
};

  render() {
    return(<div>
      {
        this.state.loading ? <div style={{textAlign: "center"}}><ProgressSpinner/> <br/> Loading ...</div> :
          this.state.allVersions.length > 0 ?
          <div>
          <Paginator template={this.template} leftContent={this.getPaginatorLeftContent()} rightContent={this.getPaginatorRightContent()} first={this.state.currVerIndex} rows={1} totalRecords={this.state.allVersions.length} onPageChange={(e) => this.setState({currVerIndex: e.first})}></Paginator>
          <StanceVersionStandaloneView stance={this.state.allVersions[this.state.currVerIndex]}/></div> :
          <div><p>There are no versions for this stance yet.</p></div>
        
      }
      <Toast ref={(el) => this.toast = el} />
      <Dialog header="All Versions" visible={this.state.allVersionsVisible} style={{ width: '100pc' }} onHide={this.toggleAllVersionsVisibility}>
        <Timeline value={this.state.timelineVersions} align="alternate" content={(item) => <StanceVersionStandaloneView stance={item}/>} />
      </Dialog>
      <Dialog header="Edit Version" visible={this.state.editOpen} onHide={this.toggleEditOpen} style={{ height: '100pc', width: '100pc' }}>
        <StanceVersionEditor stanceVersion={this.state.allVersions[this.state.currVerIndex]} action="edit" onSubmit={this.submit} submitting={this.state.submitting}/>
      </Dialog>
      <ConfirmDialog visible={this.state.delOpen} onHide={() => this.toggleDelDialog(false)} message="Are you sure you want to proceed?"
    header="Delete Version" icon="pi pi-exclamation-triangle" accept={this.delete} acceptClassName="p-button-danger"/>
    </div>)
  }
}

class StanceVersionStandaloneView extends React.Component {
  getDateString() {
    var d = new Date(this.props.stance.taken_on)
    var str = d.toDateString()
    var timeStr = d.toLocaleTimeString()
    if (timeStr !== "00:00:00") {
      str += " - "+ timeStr
    }
    return str
  }
  getFlagsRenderContent() {
    const hasFlags = this.props.stance.is_spec_temp_stance ||
          this.props.stance.is_impl_team_stance ||
          this.props.stance.is_deprecated ||
          this.props.stance.is_in_progress
    
    if(hasFlags) {
      return (<>
        <h3><u>Status</u></h3>        
          {this.props.stance.is_spec_temp_stance && <span> - This is a temporary stance due to uncertainty in specification.<br/></span>}
          {this.props.stance.is_impl_team_stance && <span> - This is a temporary stance to help with milestone implementation.<br/></span>}
          {this.props.stance.is_deprecated && <span> - This is a deprecated stance since concepts have changed drastically.<br/></span>}
          {this.props.stance.is_in_progress && <span> - This is an incomplete stance since reviewers have not completed the content.<br/></span>}
        <Divider type='dashed'/>
      </>)
    }
  }
  render() {
    return (<Card>
        <h3><u>Stance</u></h3>
        {parse(DOMPurify.sanitize(this.props.stance.desc, { ADD_ATTR: ['target'] }))}
        <Divider type='dashed'/>
        <h3><u>Reasons & References</u></h3>
        {parse(DOMPurify.sanitize(this.props.stance.reason, { ADD_ATTR: ['target'] }))}
        <Divider type='dashed'/>
        {this.getFlagsRenderContent()}
        <h4>{this.props.stance.author}</h4>
        {this.getDateString()}
      </Card>
    )
  }
}

class StanceVersionEditor extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.stanceVersion == null) {
      this.state = {
        stanceAuthor: "", stanceTakenOn: new Date("2019-04-01 00:00:00"),
        stanceDesc: "", stanceReason: "",
        stanceIsImplTeam: false, stanceIsSpecTemp: false,
        stanceIsInProg: false, stanceDeprecated: false
      }
    } else {
      this.state = {
        stanceAuthor: this.props.stanceVersion.author, stanceTakenOn: new Date(this.props.stanceVersion.taken_on),
        stanceDesc: this.props.stanceVersion.desc, stanceReason: this.props.stanceVersion.reason,
        stanceIsImplTeam: this.props.stanceVersion.is_impl_team_stance, stanceIsSpecTemp: this.props.stanceVersion.is_spec_temp_stance,
        stanceIsInProg: this.props.stanceVersion.is_in_progress, stanceDeprecated: this.props.stanceVersion.is_deprecated
      }
    }
    this.state.stanceTakenOnString = this.getStanceTakenOnString(this.state.stanceTakenOn)
    this.setStanceDesc = this.setStanceDesc.bind(this);
    this.setStanceReason = this.setStanceReason.bind(this);
    this.setStanceAuthor = this.setStanceAuthor.bind(this);
    this.setStanceTakenOn = this.setStanceTakenOn.bind(this);
    this.getStanceTakenOnString = this.getStanceTakenOnString.bind(this);
    this.toggleStanceIsImplTeam = this.toggleStanceIsImplTeam.bind(this);
    this.toggleStanceIsSpecTemp = this.toggleStanceIsSpecTemp.bind(this);
    this.toggleStanceInProg = this.toggleStanceInProg.bind(this);
    this.toggleStanceDeprecated = this.toggleStanceDeprecated.bind(this);
    this.submit = this.submit.bind(this);
  }
  setStanceDesc(editor) {
    this.setState({stanceDesc: editor.htmlValue})
  }
  setStanceReason(editor) {
    this.setState({stanceReason: editor.htmlValue})
  }
  setStanceAuthor(author) {
    this.setState({stanceAuthor: author})
  }
  setStanceTakenOn(takenOn) {
    this.setState({stanceTakenOn: takenOn, stanceTakenOnString: this.getStanceTakenOnString(takenOn)})
  }
  getStanceTakenOnString(takenOn) {
    var x = takenOn.toLocaleDateString().split("/")
    return x[2]+"-"+x[1]+"-"+x[0] +" "+ takenOn.toLocaleTimeString()
  }
  toggleStanceIsImplTeam() {
    this.setState({stanceIsImplTeam: !this.state.stanceIsImplTeam})
  }
  toggleStanceIsSpecTemp() {
    this.setState({stanceIsSpecTemp: !this.state.stanceIsSpecTemp})
  }
  toggleStanceInProg() {
    this.setState({stanceIsInProg: !this.state.stanceIsInProg})
  }
  toggleStanceDeprecated() {
    this.setState({stanceDeprecated: !this.state.stanceDeprecated})
  }
  submit() {
    var action = this.props.action;
    var params="action="+action;
    if (action === "edit") {
      params += "&stanceVersionId="+this.props.stanceVersion.id
    }
    var objKeys = Object.keys(this.state)
    for (let i = 0; i < objKeys.length; i++) {
      const key = objKeys[i];
      var value = this.state[key].toString().replaceAll("&", "%26")
      params += "&"+key+"="+value
    }
    this.props.onSubmit(action, params);
  }
  render() {
    return (<>
        <Divider type="dashed" align="left"><span className="p-tag">Author & Timestamp</span></Divider>
        By: <Dropdown value={this.state.stanceAuthor} options={[{label: "Akshhayaa", value: "Akshhayaa"}, {label: "Sridhar", value: "Sridhar"}, {label: "Joseph", value: "Joseph"}, {label: "Suresh K V", value: "Suresh K V"}]} onChange={(e) => this.setStanceAuthor(e.value)} placeholder="Select author"/> {"    "}
        On: <Calendar id="time24" dateFormat="dd-mm-yy" value={this.state.stanceTakenOn} onChange={(e) => this.setStanceTakenOn(e.value)} showTime showSeconds hideOnDateTimeSelect/>
        <Divider type="dashed" align="left"><span className="p-tag">Stance Taken</span></Divider>
        <Editor style={{ height: '175px' }} value={this.state.stanceDesc} onTextChange={(e) => this.setStanceDesc(e)} />
        <Divider type="dashed" align="left"><span className="p-tag">Reasons & References</span></Divider>
        <Editor style={{ height: '175px' }} value={this.state.stanceReason} onTextChange={(e) => this.setStanceReason(e)} />
        <Divider type="dashed" align="left"><span className="p-tag">Stance Status</span></Divider>
        <Checkbox checked={this.state.stanceIsImplTeam} onChange={(e) => this.toggleStanceIsImplTeam()} /> Is Implementation Team Stance  {"   "}
        <Checkbox checked={this.state.stanceIsSpecTemp} onChange={(e) => this.toggleStanceIsSpecTemp()} /> Is Spec Temporary Stance   {"   "}
        <Checkbox checked={this.state.stanceIsInProg} onChange={(e) => this.toggleStanceInProg()} /> Is In Progress {"   "}
        <Checkbox checked={this.state.stanceDeprecated} onChange={(e) => this.toggleStanceDeprecated()} /> Deprecated
        <Divider type="dashed"/>
        <Button label="Submit" className="p-button-raised p-button-text" onClick={this.submit} loading={this.props.submitting} disabled={this.props.submitting}/></>
    )
  }
}

class AdvancedSearch extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {titleOp: "contains", titleVal: null, descOp: "contains", descVal: null,
    reasonOp: "contains", reasonVal: null, authorOp: "is", authorVal: null, 
    takenOnOp: "on or after", takenOnVal: null, takenOnMaxVal: null, 
    implTeamVal: null, specTempVal: null, depreVal: false, inProgVal: null,
    result: [], resultIndex: 0, fetchingInProg: false, isUnauthorised: false}
    this.submit = this.submit.bind(this);
  }
  stringOps = ["contains", "not contains"]
  dateOps = ["is", "is not", "after", "before", "on or after", "on or before", "between"]

  template = {
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport', // The above keys can be set in the desired order.
    'CurrentPageReport': (options) => {
        // options.currentPage: Current selected page.
        // options.totalPages: Total pages in paginator.
        // options.first: Zero-relative number of the first row to be displayed.
        // options.last: The number of the last row to be displayed.
        // options.rows: Row count in a page.
        // options.totalRecords: Total records in paginator.
        // options.className: Style class of the default element.
        // options.element: Default element created by the component.
        // options.props: Component props.
        return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
              Total - {options.totalRecords}
          </span>
        )
      }
  };

  submit() {
    this.setState({fetchingInProg: true, resultIndex: 0})
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/server/searchStanceVersion/", true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = (e) => {
      if (xhr.status === 200) {
        this.setState({result: JSON.parse(xhr.responseText), fetchingInProg: false})
      } else if (xhr.status === 403) {
        this.setState({isUnauthorised: true})
      }
    };
    xhr.onerror = (e) => {
      console.error(xhr.statusText);
    };
    var params =  ""
    var objKeys = Object.keys(this.state)
    for (let i = 0; i < objKeys.length; i++) {
      const key = objKeys[i];
      if (key !== "result" && key !== "resultIndex" && key !== "fetchingInProg" && key !== "isUnauthorised") {
        if(i > 0) {
          params += "&"
        }
        if ((key === "takenOnVal" || key === "takenOnMaxVal") && (this.state[key] != null)) {
          var takenOn = this.state[key];
          var x = takenOn.toLocaleDateString().split("/")
          var dateVal = x[2]+"-"+x[1]+"-"+x[0] +" "+ takenOn.toLocaleTimeString()
          params += key+"="+dateVal
        } else if (this.state[key] !== null) {
          params += key+"="+this.state[key].toString().replaceAll("&", "%26")
        }
      }
    }
    xhr.send(params);
  }

  getResultContent() {
    if(this.state.fetchingInProg) {
      return (<div style={{textAlign: "center"}}><ProgressSpinner/> <br/> Loading ...</div>)
    } else if(this.state.result.length === 0) {
      return "There are no stances to display."
    } else {
      var stance = this.state.result[this.state.resultIndex]
      return <>
          <Paginator template={this.template} first={this.state.resultIndex} rows={1} totalRecords={this.state.result.length} onPageChange={(e) => this.setState({resultIndex: e.first})}></Paginator><br/>
          <b>Topic Hierarchy</b> : {"   "}
          {stance.topic_hierarchy.map((topic, index) => {
            if(index === 0) {
              return <u key={index}>{topic}</u>
            } else {
              return (<u key={index}> <i className="pi pi-angle-right"></i> {topic}</u>)
            }
          })}{"   "}<br/><br/>
          <span><b>Stance Title</b> : <u>{stance.stance_title}</u></span><br/><br/>
          <StanceVersionStandaloneView stance={stance}/>
        </>
    }
  }

  render() {
    return (
      <Panel>
        <div className="grid">
          <div className="col-4"><Fieldset legend="Filter Latest Stance By">
            <h5>Title</h5><Dropdown value={this.state.titleOp} options={this.stringOps} onChange={(e) => this.setState({titleOp: e.value})}/>{"   "}
            <InputText onChange={(e) => this.setState({titleVal: e.target.value})} />
            <h5>Stance</h5><Dropdown value={this.state.descOp} options={this.stringOps} onChange={(e) => this.setState({descOp: e.value})}/>{"   "}
            <InputText onChange={(e) => this.setState({descVal: e.target.value})} />
            <h5>Reason</h5><Dropdown value={this.state.reasonOp} options={this.stringOps} onChange={(e) => this.setState({reasonOp: e.value})}/>{"   "}
            <InputText onChange={(e) => this.setState({reasonVal: e.target.value})} />
            <h5>Author</h5><Dropdown value={this.state.authorVal} showClear options={[{label: "Akshhayaa", value: "Akshhayaa"}, {label: "Sridhar", value: "Sridhar"}, {label: "Joseph", value: "Joseph"}, {label: "Suresh K V", value: "Suresh K V"}]} onChange={(e) => this.setState({authorVal: e.value})} placeholder="Select an author"/>
            <h5>Taken On</h5><Dropdown value={this.state.takenOnOp} options={this.dateOps} onChange={(e) => this.setState({takenOnOp: e.value})}/>{"   "}
            <Calendar id="time24a" dateFormat="dd-mm-yy" value={this.state.takenOnVal} viewDate={new Date("2019-04-01 00:00:00")} onChange={(e) => this.setState({takenOnVal: e.value})} hideOnDateTimeSelect/>
            {this.state.takenOnOp === "between" && <>
            <br/><br/> and <Calendar id="time24b" dateFormat="dd-mm-yy" value={this.state.takenOnMaxVal} viewDate={new Date()} onChange={(e) => this.setState({takenOnMaxVal: e.value})} hideOnDateTimeSelect/>
            </>}
            <h5>Status</h5>
            <TriStateCheckbox value={this.state.implTeamVal} onChange={(e) => this.setState({implTeamVal: e.value})} />&nbsp;
            <label>Implementation Team Stance</label> &nbsp;&nbsp;
            <TriStateCheckbox value={this.state.specTempVal} onChange={(e) => this.setState({specTempVal: e.value})} />&nbsp;
            <label>Spec Temporary Stance</label><br/><br/>
            <Checkbox checked={this.state.depreVal} onChange={(e) => this.setState({depreVal: e.checked})} />&nbsp;
            <label>Deprecated Stance</label>&nbsp;&nbsp;
            {this.context.editEnabled &&
            <><TriStateCheckbox value={this.state.inProgVal} onChange={(e) => this.setState({inProgVal: e.value})} />&nbsp;
            <label>In Progress Stance</label></>}
            <br/><br/><Button style={{float: "right"}} label="Search" className="p-button-raised" onClick={this.submit} />
          </Fieldset></div>
          <div className="col-8"><Fieldset legend="Filtered Stances">
            {this.state.isUnauthorised ? "Unauthorised Action." : this.getResultContent()}
          </Fieldset></div>
        </div>
      </Panel>
    )
  }
}

class Faq extends React.Component {
  render() {
    return (
      <div className="grid">
          <div className="col-1"></div>
          <div className="col-10">
            <Accordion multiple>
              <AccordionTab header="What is SpecTracker?">
                  SpecTracker is a tool made by Vivekanand SV and populated by Joseph with the help of other Pali team members to highlight the latest stances in Pali. <br/><br/>
          SpecTracker in NOT a primary documentation tool. It is an aggregator of other documentation tools and provides a consildated view of such resources grouped by topics.
              </AccordionTab>
              <AccordionTab header="How is information arranged in the SpecTracker?">
                  Information is arranged in a tree-like structure. Broadly speaking, data is grouped in "Stances" which are grouped under "Topics". <br/><br/>
          Each topic can have multiple sub-topics and each stance can have multiple versions.
              </AccordionTab>
              <AccordionTab header="How do I find information about a topic?">
                  The search bar under the "Topics" heading is the best place to start. This searches across all topics and sub-topics. <br/><br/>
          If you want to search all the stances try the "Search Stances" tab at the top of the page. If you still can't find what you're looking for, reach out to the spec or documentation team, and they will let you know the latest stance and update SpecTracker accordingly.
              </AccordionTab>
              <AccordionTab header="How do I share links to specific topics/stances on SpecTracker?">
                  Due to limitations, there are no URLs for each entity in SpecTracker. Use the topic name, stance name and the required version numbers to share information between yourselves for now.
              </AccordionTab>
              <AccordionTab header="What if there is a new information about a Pali feature?">
                  This is where "Versions" come in to play. Each "Stance" can be filled with multiple versions. What is right in Pali today, may not be right tomorrow, the language is evolving and this tool helps track that evolution. Each decision to change the spec in Pali can be mapped to a particular "Version" on SpecTracker.
              </AccordionTab>
              <AccordionTab header="Who maintains SpecTracker?">
                  For now, the application is shared in a read-only mode and only a few users can add new stances and versions. <br/><br/>
          In the future, anyone who recieves new information about Pali will add it to the SpecTracker via a review process similar to ZAsk. More on this later.
              </AccordionTab>
              <AccordionTab header="What does a 'Version' contain?">
                  Each "Version" contains a description that outlines the stance on that topic along with a Cliq, ZAsk, Writer, Learn or Code reference that explains the reasons for the decision in detail. <br/><br/>
          To know when this stance was taken, refer the bottom of the version to find a date and the who decided on this stance.
              </AccordionTab>
              <AccordionTab header="Can there be different 'Versions' for a 'Stance'? How do I find them?">
                  Each decision to change the spec in Pali can be mapped to a particular "Version". What is right in Pali today, may not be right tomorrow, the language is evolving and this tool helps track that evolution. <br/><br/>
          Based on the date associated to a version, it is assigned a version number. The latest version is displayed by default; click on the navigation buttons at the top of a version to view: <br/><br/>
          1. All versions in a timeline view <br/><br/>
          2. The initial, previous, next or latest version on this subject.
              </AccordionTab>
              <AccordionTab header="What is 'Active Discussions'?">
                  This pane helps everyone know what topics the spec team is actively working on and the status of discussions on that topic. 
              </AccordionTab>
              <AccordionTab header="What is 'Related Topics'?">
                  Some topics depend on the same core semantics, e.g. filters and unique, parameters and tuples. These are listed under the "Related Topics" and serve as a note to readers to refer the related topics as well for a holistic picture on a topic they are studying.
              </AccordionTab>
              <AccordionTab header="The SpecTracker does not contain the latest information about a particular topic. What do I do?">
                  Reach out to the spec or documentation team and they will confirm if the stance you have is correct and update SpecTracker accordingly. <br/><br/>
          Till a review process is implemented, if you would like to volunteer to verify or add data, reach out to Joseph.
              </AccordionTab>
              <AccordionTab header="A reference link in SpecTracker is not accessible. What now?">
                  Contact Joseph. The link may be incorrect or a private document on Writer a message not forwarded to the "Private Links" Cliq channel.
              </AccordionTab>
            </Accordion>
          </div>
        <div className="col-1"></div>
    </div>
    )
  }
}

ReactDOM.createRoot(document.getElementById('root')).render(<App/>)

// // // If you want to start measuring performance in your app, pass a function
// // // to log results (for example: reportWebVitals(console.log))
// // // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// // reportWebVitals(console.log);
